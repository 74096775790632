@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  letter-spacing: 0.04em;
  text-underline-offset: 3px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
